import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import rightCarExample from "../../assets/car_example.png";
import backCarExample from '../../assets/icon/back.png';
import downLoad from '../../assets/icon/download.svg';
import frontCarExample from '../../assets/icon/front.png';
import leftCarExample from '../../assets/icon/left.png';
import OdometerIcon from '../../assets/icon/Odometer.png';
import odometerCarExample from '../../assets/icon/OdometerCar.png';
import seguroAutoLiver from '../../assets/seguro_auto_liverpool.png';
import Alerta from "../../components/Alerta";
import CameraModal from "../../components/camera_modal";
import ImportantPointsCard from "../../components/important_points_card";
import PhotoCard from "../../components/photo_card";
import ProgressBar from "../../components/progress_bar";
import { formatNumber } from "../../hooks/formatNumber";
import { useAuth } from "../../provider/AuthProvider";
import { usePoliza } from "../../provider/PolizaProvider";

//DataLayer Google Tag Manager
import Modal from "../../components/Modal";
import { pushToDataLayer } from "../../lib/pushToDataLayer";

const FotosIniciales = ({ textFooterLegal }) => {
  const { user } = useAuth();
  const { poliza, sendImageBase64, sendOdometerBase64, confirmOdometer, downloadPoliza } =
    usePoliza();
  const navigate = useNavigate();

  const [frontalPhoto, setFrontalPhoto] = useState(null);
  const [izquierdaPhoto, setIzquierdaPhoto] = useState(null);
  const [derechaPhoto, setDerechaPhoto] = useState(null);
  const [traseraPhoto, setTraseraPhoto] = useState(null);
  const [odometroPhoto, setOdometroPhoto] = useState(null);
  const [odometro, setOdometro] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [frontalPhotoModal, setFrontalPhotoModal] = useState(false);
  const [izquierdaPhotoModal, setIzquierdaPhotoModal] = useState(false);
  const [derechaPhotoModal, setDerechaPhotoModal] = useState(false);
  const [traseraPhotoModal, setTraseraPhotoModal] = useState(false);
  const [odometroPhotoModal, setOdometroPhotoModal] = useState(false);
  const [odometroModal, setOdometroModal] = useState(false);

  const [paso1, setPaso1] = useState(false);
  const [paso2, setPaso2] = useState(false);
  const [paso3, setPaso3] = useState(false);
  const [paso4, setPaso4] = useState(false);
  const [paso5, setPaso5] = useState(false);
  const [paso6, setPaso6] = useState(false);
  const [thankyouPage, setThankyouPage] = useState(false);
  const [stepProgressBar, setStepProgressBar] = useState(1)

  const resetFotosIniciales = () => {
    setDerechaPhotoModal(false);
    setFrontalPhoto(null);
    setPaso1(false);
    setIzquierdaPhoto(null);
    setPaso2(false);
    setTraseraPhoto(null);
    setPaso3(false);
    setDerechaPhoto(null);
    setPaso4(false);
  };
  const resetFotosOdometro = () => {
    setPaso5(false);
    setOdometro(null);
    setOdometroPhoto(null);
    setOdometroModal(false);
  };

  const handleFotoInicial = async () => {
    window.scrollTo(0, 0)
    const loadingElement = document.getElementById('loading');
    try {
      loadingElement.classList.remove('hidden');
      document.body.style.overflow = 'hidden';
      const res_frontal_foto = await sendImageBase64(
        poliza.policy.id,
        "front",
        frontalPhoto.replace("data:image/png;base64,", ""),
        user.authToken
      );
      if (res_frontal_foto.data?.status != "success") {
        resetFotosIniciales();
        loadingElement.classList.add('hidden');
        document.body.style.overflow = '';
        setIsAlert(true)
        setAlertMessage("La foto frontal no fue enviada. Por favor, inténtalo más tarde.")
      }
      const res_izquierda_foto = await sendImageBase64(
        poliza.policy.id,
        "left",
        izquierdaPhoto.replace("data:image/png;base64,", ""),
        user.authToken
      );
      if (res_izquierda_foto.data?.status != "success") {
        resetFotosIniciales();
        loadingElement.classList.add('hidden');
        document.body.style.overflow = '';
        setIsAlert(true)
        setAlertMessage("La foto izquierda no fue enviada. Por favor, inténtalo más tarde.")
      }
      const res_trasera_foto = await sendImageBase64(
        poliza.policy.id,
        "back",
        traseraPhoto.replace("data:image/png;base64,", ""),
        user.authToken
      );
      if (res_trasera_foto.data?.status != "success") {
        resetFotosIniciales();
        loadingElement.classList.add('hidden');
        document.body.style.overflow = '';
        setIsAlert(true)
        setAlertMessage("La foto trasera no fue enviada. Por favor, inténtalo más tarde.")
      }
      const res_derecha_foto = await sendImageBase64(
        poliza.policy.id,
        "right",
        derechaPhoto.replace("data:image/png;base64,", ""),
        user.authToken
      );
      if (res_derecha_foto.data?.status != "success") {
        resetFotosIniciales();
        loadingElement.classList.add('hidden');
        document.body.style.overflow = '';
        setIsAlert(true)
        setAlertMessage("La foto derecha no fue enviada. Por favor, inténtalo más tarde.")
      }
      setDerechaPhotoModal(false);
      setPaso4(true);
      const eventParams = {
        event_category: 'Cargar fotografias',
        step: '1A Lateral derecha',
        click: 'Siguiente'
      }
      pushToDataLayer('clic_fotografia_derecha_siguiente', eventParams)
    } catch (error) {
      resetFotosIniciales();
      loadingElement.classList.add('hidden');
      document.body.style.overflow = '';
      console.error(error)
      setIsAlert(true)
      setAlertMessage("Ocurrió un error y no fue posible enviar tus fotografías. Por favor, inténtalo más tarde.")
    } finally {
      loadingElement.classList.add('hidden');
      document.body.style.overflow = '';
      setStepProgressBar(2)

    }
  };

  const handleConfirm = async () => {
    const loadingElement = document.getElementById('loading');
    loadingElement.classList.remove('hidden');
    document.body.style.overflow = 'hidden';
    setOdometroModal(false);
    resetFotosIniciales()
    try {
      const res_odometroPhoto = await sendOdometerBase64(
        poliza.policy.id,
        odometro,
        odometroPhoto.replace("data:image/png;base64,", ""),
        user.authToken
      );
      if (res_odometroPhoto.data?.status != "success") {
        resetFotosOdometro();
        setIsAlert(true)
        setAlertMessage("Ocurrió un error y no fue posible enviar tus fotografías. Por favor, inténtalo más tarde.")
        return;
      }
      const res_odometroConfirm = await confirmOdometer(
        poliza.policy.id,
        odometro,
        poliza.client.client_id,
        poliza.client.client_token,
        user.authToken
      );
      if (res_odometroConfirm.data?.status != "success") {
        resetFotosOdometro();
        setIsAlert(true)
        setAlertMessage("Ocurrió un error y no fue posible enviar tus fotografías. Por favor, inténtalo más tarde.")
        return;
      }
      loadingElement.classList.add('hidden');
      document.body.style.overflow = '';
      setPaso5(false);
      setPaso6(false);
      setThankyouPage(true)
      const eventParams = {
        event_category: 'Cargar fotografias',
        step: '3 Confirmacion de datos',
        click: 'Confirmar'
      }
      pushToDataLayer('clic_km_odometro_confirmar', eventParams)
      const eventParamsPromass = {
        phone: JSON.parse(localStorage.getItem('user')).celphone,
        poliza: poliza.policy.id,
        escenario: 'Registrar vehiculo',
        pageview: 'Registrar km odometro',
      }
      pushToDataLayer('autopagoxkm_activacion_exitosa_screenview', eventParamsPromass)
    }
    catch (error) {
      loadingElement.classList.add('hidden');
      document.body.style.overflow = '';
      if (error.response) {
        // La respuesta del servidor está fuera del rango 2xx
        const errorData = error.response.data;
        const errorMessage = errorData.result || `Error ${error.response.status}: ${error.response.data?.result}`;
        resetFotosIniciales();
        resetFotosOdometro();
        setPaso6(false);
        setPaso5(false);
        setIsAlert(true);
        setAlertMessage(errorMessage);
      } else if (error.request) {
        // No se recibió respuesta del servidor
        resetFotosIniciales();
        resetFotosOdometro();

        setIsAlert(true)
        setAlertMessage("No se pudo conectar con el servidor. Por favor intenta nuevamente.");

      } else {
        // Error al configurar la solicitud
        resetFotosIniciales();
        resetFotosOdometro();
        setIsAlert(true)
        setAlertMessage("Error desconocido al realizar la solicitud.");

      }

    }

    // navigate("/dashboard");
  };
  useEffect(() => {
    const eventParams = {
      phone: JSON.parse(localStorage.getItem('user')).celphone,
      poliza: poliza.policy.id,
      pageview: 'Fotografia frontal',
      escenario: 'Registrar vehiculo',
    }
    pushToDataLayer('autopagoxkm_fotografia_frontal_screenview', eventParams)
    if (
      !(
        (poliza.policy.hasVehiclePictures == false || poliza.policy.hasOdometerPicture == false) &&
        poliza.policy.reportState == 13
      )
    ) {
      navigate("/dashboard");
    }
  }, [])

  const handleDownloadPoliza = async () => {
    const loadingElement = document.getElementById('loading');
    loadingElement.classList.remove('hidden');
    await downloadPoliza(poliza.policy.id, user.authToken);
    loadingElement.classList.add('hidden');

    const eventParams = {
      event_category: 'Registro exitoso',
      click: 'Descarga tu poliza'
    }
    pushToDataLayer('clic_descarga_tu_poliza', eventParams)
  }

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className={`container mx-auto max-w-md ${thankyouPage ? '' : 'px-4'}`}>
      <div className={`mt-4 ${thankyouPage && 'hidden'}`}>
        <Alerta isAlertaOpen={isAlert} setAlert={setIsAlert} isTextAlert={alertMessage} isIconClose={'error'} isTimeClose={3000} />
        <ProgressBar currentStep={stepProgressBar} />
      </div>
      <div
        className={`relative flex flex-col mx-auto mt-6 mb-4 text-liverpoolCarbon w-full ${thankyouPage && 'hidden'}`}
      >
        {(paso5 == true && paso6 == false) || (paso6) || (
          <p>{stepProgressBar === 2 ? 'Por favor, toma una fotografía de tu odómetro tal como se muestra en la imagen de ejemplo.'
            : stepProgressBar === 1 ? 'A continuación te solicitaremos cuatro fotografías, como evidencia para la aseguradora y tu tranquilidad en caso de un siniestro.'
              : ''}</p>
        )}

      </div>

      {
        thankyouPage || (
          (paso5 === true && paso6 === false) || paso6 ? null : (
            <ImportantPointsCard isOdometer={paso4 === true && paso5 === false} />
          )
        )
      }

      {
        thankyouPage || (
          (paso1 == false && (
            <PhotoCard
              carPhoto={frontCarExample}
              subtitle={(<p className=" text-sm text-liverpoolLead my-2">
                En la fotografía deberá visualizarse <span className="font-bold">la parte frontal</span> de tu vehículo, tal como se muestra en la imagen de ejemplo.
              </p>)}
              title={'1.- Fotografía frontal'}
              poliza={poliza}
              onHandlePhotoButton={() => {
                setFrontalPhotoModal(true)

              }}
            />
          ))
        )
      }


      {frontalPhotoModal && (
        <CameraModal
          className={"z-20"}
          title={"Fotografía frontal"}
          cancel={() => {
            setFrontalPhoto(null);
            setFrontalPhotoModal(false);
          }}
          onHandleTakePhoto={(dataUri) => {
            setFrontalPhoto(dataUri);
          }}
          photo={frontalPhoto}
          onHandleConfirmPhoto={() => {
            setFrontalPhotoModal(false);
            setPaso1(true);
            const eventParams = {
              event_category: 'Cargar fotografias',
              step: '1B Frontal',
              click: 'Siguiente'
            }
            pushToDataLayer('clic_fotografia_frontal_siguiente', eventParams)
            const eventParamsPromass = {
              phone: JSON.parse(localStorage.getItem('user')).celphone,
              poliza: poliza.policy.id,
              pageview: 'Fotografia frontal',
              escenario: 'Registrar vehiculo',
              link_name: 'Confirmar'
            }
            pushToDataLayer('autopagoxkm_fotografia_frontal_clic_button_confirmar', eventParamsPromass)

            const eventParamsPromassView = {
              phone: JSON.parse(localStorage.getItem('user')).celphone,
              poliza: poliza.policy.id,
              escenario: 'Registrar vehiculo',
              pageview: 'Fotografia lateral izquierda',
            }
            pushToDataLayer('autopagoxkm_fotografia_lateral_izquierda_screenview', eventParamsPromassView)
          }}
          onHandleCancelPhoto={() => setFrontalPhoto(null)}
          confirmTitle="Confirmar"
        />
      )}

      {paso1 == true && paso2 == false && (
        <PhotoCard carPhoto={leftCarExample} title={'2.- Fotografía lateral izquierda'} subtitle={(<p className=" text-sm text-liverpoolLead my-2">
          En la fotografía deberá visualizarse <span className="font-bold">la parte lateral izquierda</span> de tu vehículo, tal como se muestra en la imagen de ejemplo.
        </p>)} poliza={poliza} onHandlePhotoButton={() => setIzquierdaPhotoModal(true)} />
      )}

      {izquierdaPhotoModal && (
        <CameraModal
          className={"z-20"}
          title={"Fotografía lateral izquierda"}
          cancel={() => {
            setIzquierdaPhoto(null);
            setIzquierdaPhotoModal(false);
          }}
          onHandleTakePhoto={(dataUri) => {
            setIzquierdaPhoto(dataUri);
          }}
          photo={izquierdaPhoto}
          onHandleConfirmPhoto={() => {
            setIzquierdaPhotoModal(false);
            setPaso2(true);
            const eventParams = {
              event_category: 'Cargar fotografias',
              step: '1C Lateral izquierda',
              click: 'Siguiente'
            }
            pushToDataLayer('autopagoxkm_fotografia_lateral_izquierda_clic_button_confirmar', eventParams)
            const eventParamsPromass = {
              phone: JSON.parse(localStorage.getItem('user')).celphone,
              poliza: poliza.policy.id,
              pageview: 'Fotografia lateral izquierda',
              escenario: 'Registrar vehiculo',
              link_name: 'Confirmar'
            }
            pushToDataLayer('autopagoxkm_fotografia_lateral_izquierda_button_confirmar', eventParamsPromass)
            const eventParamsPromassView = {
              phone: JSON.parse(localStorage.getItem('user')).celphone,
              poliza: poliza.policy.id,
              escenario: 'Registrar vehiculo',
              pageview: 'Fotografia trasera',
            }
            pushToDataLayer('autopagoxkm_fotografia_trasera_screenview', eventParamsPromassView)
          }}
          onHandleCancelPhoto={() => setIzquierdaPhoto(null)}
          confirmTitle="Confirmar"
        />
      )}

      {paso2 == true && paso3 == false && (
        <PhotoCard carPhoto={backCarExample} title={'3.- Fotografía trasera'} subtitle={(<p className=" text-sm text-liverpoolLead my-2">
          En la fotografía deberá visualizarse <span className="font-bold">la parte trasera</span> de tu vehículo, tal como se muestra en la imagen de ejemplo.
        </p>)} poliza={poliza} onHandlePhotoButton={() => setTraseraPhotoModal(true)} />
      )}

      {traseraPhotoModal && (
        <CameraModal
          className={"z-20"}
          title={"Fotografía trasera"}
          cancel={() => {
            setTraseraPhoto(null);
            setTraseraPhotoModal(false);
          }}
          onHandleTakePhoto={(dataUri) => {
            setTraseraPhoto(dataUri);
          }}
          photo={traseraPhoto}
          onHandleConfirmPhoto={() => {
            setTraseraPhotoModal(false);
            setPaso3(true);
            const eventParams = {
              event_category: 'Cargar fotografias',
              step: '1D Trasera',
              click: 'Siguiente'
            }
            pushToDataLayer('clic_fotografia_trasera_siguiente', eventParams)
            const eventParamsPromass = {
              phone: JSON.parse(localStorage.getItem('user')).celphone,
              poliza: poliza.policy.id,
              pageview: 'Fotografia trasera',
              escenario: 'Registrar vehiculo',
              link_name: 'Confirmar'
            }
            pushToDataLayer('autopagoxkm_fotografia_trasera_clic_button_confirmar', eventParamsPromass)
            const eventParamsPromassView = {
              phone: JSON.parse(localStorage.getItem('user')).celphone,
              poliza: poliza.policy.id,
              escenario: 'Registrar vehiculo',
              pageview: 'Fotografia lateral derecha',
            }
            pushToDataLayer('autopagoxkm_fotografia_lateral_derecha_screenview', eventParamsPromassView)
          }}
          onHandleCancelPhoto={() => setTraseraPhoto(null)}
          confirmTitle="Confirmar"
        />
      )}

      {paso3 == true && paso4 == false && (
        <PhotoCard carPhoto={rightCarExample} title={'4.- Fotografía lateral derecha'} subtitle={(<p className=" text-sm text-liverpoolLead my-2">
          En la fotografía deberá visualizarse <span className="font-bold">la parte lateral derecha</span> de tu vehículo, tal como se muestra en la imagen de ejemplo.
        </p>)} poliza={poliza} onHandlePhotoButton={() => setDerechaPhotoModal(true)} />
      )}

      {derechaPhotoModal && (
        <CameraModal
          className={"z-20"}
          title={"Fotografía lateral derecha"}
          cancel={() => {
            setDerechaPhoto(null);
            setDerechaPhotoModal(false);
          }}
          onHandleTakePhoto={(dataUri) => {
            setDerechaPhoto(dataUri);
          }}
          photo={derechaPhoto}
          onHandleConfirmPhoto={() => {
            setDerechaPhotoModal(false);
            handleFotoInicial()
            const eventParamsPromass = {
              phone: JSON.parse(localStorage.getItem('user')).celphone,
              poliza: poliza.policy.id,
              pageview: 'Fotografia lateral derecha',
              escenario: 'Registrar vehiculo',
              link_name: 'Confirmar'
            }
            pushToDataLayer('autopagoxkm_fotografia_lateral_derecha_clic_button_confirmar', eventParamsPromass)
            const eventParamsPromassView = {
              phone: JSON.parse(localStorage.getItem('user')).celphone,
              poliza: poliza.policy.id,
              escenario: 'Registrar vehiculo',
              pageview: 'Fotografia odometro',
            }
            pushToDataLayer('autopagoxkm_fotografia_odometro_screenview', eventParamsPromassView)
          }}
          onHandleCancelPhoto={() => setDerechaPhoto(null)}
          confirmTitle="Confirmar"
        />
      )}

      {paso4 == true && paso5 == false && (
        <PhotoCard
          carPhoto={odometerCarExample}
          title={null} poliza={poliza}
          onHandlePhotoButton={() => {
            setOdometroPhotoModal(true)
            const eventParams = {
              event_category: 'Cargar fotografias',
              step: '2 Odometro',
              click: 'Tomar fotografia'
            }
            pushToDataLayer('clic_tomar_fotografia_odometro', eventParams)
          }}
        />
      )}

      {odometroPhotoModal && (
        <CameraModal
          className={"z-20"}
          title={"Por favor, muestra los números de tu odómetro"}
          cancel={() => {
            setOdometroPhoto(null);
            setOdometroPhotoModal(false);
          }}
          onHandleTakePhoto={(dataUri) => {
            setOdometroPhoto(dataUri);
          }}
          photo={odometroPhoto}
          onHandleConfirmPhoto={() => {
            setOdometroPhotoModal(false);
            setPaso5(true);
            const eventParams2BOdo = {
              event_category: 'Cargar fotografias',
              step: '2B Odometro fotografia',
              click: 'Siguiente'
            }
            pushToDataLayer('clic_fotografia_odometro_siguiente', eventParams2BOdo)

            const eventParamsPromass = {
              phone: JSON.parse(localStorage.getItem('user')).celphone,
              poliza: poliza.policy.id,
              pageview: 'Fotografia trasera',
              escenario: 'Registrar vehiculo',
              link_name: 'Confirmar'
            }
            pushToDataLayer('autopagoxkm_fotografia_odometro_clic_button_confirmar', eventParamsPromass)
            const eventParamsPromassView = {
              phone: JSON.parse(localStorage.getItem('user')).celphone,
              poliza: poliza.policy.id,
              escenario: 'Registrar vehiculo',
              pageview: 'Fotografia odometro',
            }
            pushToDataLayer('autopagoxkm_ingresar_kilometros_screenview', eventParamsPromassView)
          }}
          onHandleCancelPhoto={() => setOdometroPhoto(null)}
          confirmTitle="Confirmar"
        />
      )}

      {paso5 == true && paso6 == false && (
        // <PhotoCard carPhoto={odometroPhoto} title={'Odometro'} poliza={poliza} onHandlePhotoButton={() => {
        //   if (odometro) {
        //     // setOdometroModal(true);
        //     setPaso6(true);

        //   }
        // }} titleButton="Subir Odometro" >
        <>
          <div className="px-4 pt-4 card space-y-4">
            <img
              src={odometroPhoto}
              alt="card-image"
              className="h-auto w-62 rounded-lg mx-auto"
            />
            <input
              type="number"
              id="odometro"
              name="odometro"
              value={odometro || ""}
              autoFocus
              onChange={(e) => setOdometro(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Introduce tu odómetro actual"
              required
            />
            <p className="text-liverpoolLead text-xs"> <strong>Importante:</strong> El odómetro declarado deberá corresponder a la foto de la unidad asegurada.</p>
          </div>
          <button
            className="w-full mt-4 text-white rounded-md font-medium text-sm px-5 py-2.5 text-center bg-liverpoolPink"
            type="button"
            onClick={() => {
              if (odometro) {
                // setOdometroModal(true);
                setPaso6(true);
                setStepProgressBar(3)
                const eventParams = {
                  event_category: 'Cargar fotografias',
                  step: '2C Odometro declarado',
                  click: 'Siguiente'
                }
                pushToDataLayer('clic_cargar_km_odometro_siguiente', eventParams)
                const eventParamsPromass = {
                  phone: JSON.parse(localStorage.getItem('user')).celphone,
                  poliza: poliza.policy.id,
                  escenario: 'Registrar vehiculo',
                  pageview: 'Registrar km odometro',
                  link_name: 'Siguiente',
                  numero_km_odometro: odometro
                }
                pushToDataLayer('autopagoxkm_fotografia_odometro_clic_button_confirmar', eventParamsPromass)
              }
            }}
          >
            Siguiente
          </button>
        </>

        // </PhotoCard>
      )}

      {paso6 === true && (
        <>
          <div className="bg-white shadow-md rounded-lg max-w-md w-full py-4 mx-auto">
            <div className="w-full border-b px-4 mb-4">
              <h2 className="text-sm font-semibold text-gray-800 pb-2">
                Por favor, confirma los siguientes datos
              </h2>
            </div>
            <div className="px-4">
              <p className="mb-4">
                Tu odómetro actual es de:
              </p>
              <p className="text-4xl text-center">{formatNumber(Number(odometro)) || ''}</p>
              <p className="text-center">Kilómetros</p>
            </div>
          </div>
          <div className="mb-4">
            <button
              className="w-full mt-4 text-white rounded-md font-medium text-sm px-5 py-2.5 text-center bg-liverpoolPink"
              type="button"
              onClick={handleConfirm}
            >
              Confirmar
            </button>
            <button
              className="w-full mt-4 text-liverpoolPink rounded-md border-liverpoolPink border-solid border-2 font-medium text-sm px-5 py-2.5 text-center bg-white"
              type="button"
              onClick={() => {
                setOdometro(null);
                setPaso6(false);
              }}
            >
              Modificar

            </button>
          </div>
        </>

      )}

      {thankyouPage === true && (
        <>
          <div>
            <div className="bg-white shadow px-2">
              <div className="grid grid-cols-2 space-x-2 md:space-x-6 py-6 gap-2 md:gap-0">
                <div className="flex col-span-1 md:col-span-1 items-center  justify-end">
                  <img src={seguroAutoLiver} alt="Logo de seguro auto Liverpool" width={130} height={35} className="h-[35px] w-[130px]" />
                </div>
                <div className="grid col-span-1 md:col-span-1 pt-0 md:pt-0 items-center  justify-center md:justify-start lg:w-8/12">
                  <p className="text-liverpoolPink font-semibold text-lg">{user?.name}</p>
                  <p className="text-liverpoolCarbon"><strong>Agradecemos tu confianza.</strong> <br />
                    Las fotografías de tu vehículo y odómetro se registraron con éxito.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="bg-white flex items-center justify-between p-4 space-x-4">
            <div className="w-full">
              <img src={seguroAutoLiver} alt="Logo de seguro auto Liverpool" width={114} height={80} />
            </div>
            <div>
              <p className="font-bold text-liverpoolPink text-lg">Javier</p>
              <p className="text-liverpoolCarbon"><strong>Agradecemos tu confianza.</strong> <br />
                Las fotografías de tu vehículo y odómetro se registraron con éxito.</p>
            </div>
          </div> */}
          <div className="px-4 mt-8">
            <div className="bg-white">
              <div className="border-b p-4">
                <p className="text-liverpoolCarbon font-bold">Información importante</p>
              </div>
              <div className="p-4" >
                <div className="flex items-start justify-between space-x-4">
                  <img className="h-auto" src={OdometerIcon} alt="Icono de odometro" height={20} width={30} />
                  <div className="text-xs">
                    <p className="font-bold">No olvides reportar tu odómetro</p>
                    <p>Recuerda enviar una foto de tu odómetro cada mes para saber los kilómetros que recorriste y procedas a realizar el pago mensual correspondiente.</p>
                  </div>
                </div>
                <p className="text-xs mt-4">Ponemos a tu disposición un centro especializado de atención a clientes,
                  en caso de que tengas alguna duda comunícate al <a className="font-bold" href="tel: 800 200 0019">800 200 0019</a> .</p>
              </div>
            </div>
            <div className="card mt-6">
              <div className="flex items-center justify-between">
                <button
                  onClick={handleDownloadPoliza}
                >
                  Descarga tu póliza
                </button>
                <img src={downLoad} alt="Icono de descarga" />
              </div>
            </div>
            <div className="mt-6">
              <button className="w-full mt-4 text-white rounded-md font-medium text-sm px-5 py-2.5 text-center bg-liverpoolPink"
                onClick={() => {
                  const eventParams = {
                    event_category: 'Cargar fotografias',
                    step: '4 Terminar ',
                    click: 'Terminar'
                  }
                  pushToDataLayer('clic_registro_exitoso_terminar', eventParams)
                  navigate("/dashboard")
                }}
              >
                Terminar
              </button>
            </div>
          </div>
        </>
      )}
      {/* {odometroModal && (
        <ConfirmOdometerModal
          className={"z-20"}
          title={"Correo electrónico"}
          text={"¿Tú odometro es correcto?"}
          cancel={() => {
            setOdometro(null);
            setOdometroModal(false);
          }}
          odometro={odometro}
          onHandleConfirm={handleConfirm}
          confirmTitle="Confirmar"
        />
      )} */}
      <div className="text-center pb-4 mt-16">
        <button className="link text-xs" onClick={(event) => {
          event.preventDefault()
          openModal()
        }}>
          Consulta Términos legales
        </button>
      </div>
      {isModalOpen && (
        <Modal className="text-center" description={textFooterLegal} closeModal={closeModal} />
      )}
    </div>
  );
};

export default FotosIniciales;
