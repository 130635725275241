import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Alerta from "../components/Alerta";
import { useAuth } from "../provider/AuthProvider";

//Funcion para DataLayer Google
import { pushToDataLayer } from "../lib/pushToDataLayer";

const Login = () => {
  const { login } = useAuth();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [token, setToken] = useState('');
  const [alert, setAlert] = useState(false);
  const [errorInput, setErrorInput] = useState("");
  const [tokenForm, setTokenForm] = useState(false);

  const navigate = useNavigate();
  const handleLogin = (e) => {
    e.preventDefault();
    const eventParams = {
      pageview: 'Inicio',
      escenario: 'Iniciar_sesion',
      link_name: 'Continuar',
      phone: phoneNumber
    }
    pushToDataLayer('autopagoxkm_activacion_poliza_clic_button_continuar', eventParams)
    const loadingElement = document.getElementById('loading');
    loadingElement.style.display = '';
    loadingElement.classList.remove('hidden');
    document.body.style.overflow = 'hidden';
    axios.post(import.meta.env.VITE_BACK_URL + '/api/getCode', {
      phone_number: phoneNumber
    }).then(function ({ data, status }) {
      if (status == 200) {
        const eventParams = {
          event_category: 'Login',
          step: '1 ingresa numero celular',
          click: 'Continuar'
        }
        pushToDataLayer('clic_seguro_autopago_continuar', eventParams)

        const eventParamsPromass = {
          pageview: 'Codigo de verificacion',
          phone: phoneNumber,
          escenario: 'Home'
        }
        pushToDataLayer('autopagoxkm_verificacion_celular_screenview', eventParamsPromass)

        setTokenForm(true);
      }
      loadingElement.classList.add('hidden');
    })
      .catch(function ({ message, response }) {
        setErrorInput(response?.data?.message ? response.data?.message : 'Error en el servidor Intente más tarde');
        loadingElement.classList.add('hidden');
        setAlert(true);
      });
  };

  const handleToken = (e) => {
    setAlert(false);
    e.preventDefault();
    const eventParams = {
      pageview: 'Polizas contratadas',
      phone: phoneNumber,
      escenario: 'Home',
      process_available: false
    }
    pushToDataLayer('autopagoxkm_verificacion_celular_clic_button_continuar', eventParams)
    const loadingElement = document.getElementById('loading');
    loadingElement.classList.remove('hidden');
    document.body.style.overflow = 'hidden';
    axios.post(import.meta.env.VITE_BACK_URL + '/api/login', {
      phone_number: phoneNumber,
      token: token
    }).then(function ({ data }) {
      const eventParams = {
        pageview: 'Codigo de verificacion',
        phone: phoneNumber,
        escenario: 'Home',
        process_available: data?.message == "Usuario autenticado"
      }
      pushToDataLayer('autopagoxkm_verificacion_celular_clic_button_continuar', eventParams)
      if (data?.message == "Usuario autenticado") {
        login({
          id: data.usuario.id,
          name: data.usuario.name,
          last_name: data.usuario.last_name,
          m_last_name: data.usuario.m_last_name,
          email: data.usuario.email,
          celphone: data.usuario.celphone,
          authToken: data.token,
        });
        const eventParams = {
          event_category: 'Login',
          step: '2 ingresa codigo de verificacion',
          click: 'Iniciar'
        }
        pushToDataLayer('clic_seguro_autopago_iniciar', eventParams)
        navigate('/dashboard');
      }
      else {
        loadingElement.classList.add('hidden');
        setErrorInput(data?.message ? data?.message : 'Error en el servidor Intente más tarde');
        setTokenForm(!tokenForm);
        setToken("")
        setAlert(true);
      }
    })
      .catch(function ({ message, response }) {
        loadingElement.classList.add('hidden');
        setErrorInput(response?.data?.message ? response.data?.message : 'Error en el servidor Intente más tarde');
        setTokenForm(!tokenForm);
        setToken("")
        setAlert(true);
      });
  }

  useEffect(() => {
    const eventParams = {
      pageview: 'Inicio',
      escenario: 'Iniciar_sesion'
    }
    pushToDataLayer('autopagoxkm_activacion_poliza_screenview', eventParams)
  }, [])


  return (
    <>
      <div className="card !rounded-none">
        <p className="font-bold text-xl text-liverpoolCarbon text-center"> ¡Bienvenido!</p>
      </div>
      <div className="container m-auto px-4">

        <>
          {alert && (
            <Alerta isAlertaOpen={alert} setAlert={setAlert} isTextAlert={errorInput} isIconClose={'error'} isTimeClose={3000} />
          )}
          <div className="mx-auto h-full">
            {tokenForm ? (
              <form onSubmit={handleToken} className="space-y-6 mx-auto max-w-sm">
                <div className="p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8">
                  <h5 className="text-sm block mb-2 text-gray-900">Código de Verificación</h5>
                  <div>
                    <label htmlFor="token" className="block mb-2 text-sm text-liverpoolLead">
                      Ingresa el código de verificación que hemos enviado vía mensaje de texto al <span className="font-bold text-liverpoolLead">{phoneNumber}</span>.
                    </label>
                    <input
                      type="text"
                      name="token"
                      id="token"
                      className="uppercase placeholder:italic placeholder:text-slate-400 bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      value={token}
                      placeholder="Código"
                      onChange={(e) => setToken(e.target.value)}
                      required
                    />
                  </div>
                  <div className="text-center mt-8">

                    <a href="#" type="button" onClick={() => setTokenForm(false)} className="inline-flex font-medium items-center text-liverpoolPink text-xs underline">
                      Enviar código nuevamente
                    </a>
                  </div>
                </div>
                <button type="submit" className="w-full mt-4 text-white rounded-md font-medium text-sm px-5 py-2.5 text-center bg-liverpoolPink">
                  Iniciar
                </button>
              </form>
            ) : (
              <form className="space-y-6 m-auto max-w-sm" onSubmit={handleLogin}>
                <div className="p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8">
                  <label htmlFor="phoneNumber" className="block mb-2 text-sm text-gray-900">
                    Si ya cuentas con una póliza, puedes iniciar sesión con tu número celular.
                  </label>
                  <input
                    type="tel"
                    name="phoneNumber"
                    id="phoneNumber"
                    className="placeholder:italic placeholder:text-slate-400 bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={phoneNumber}
                    placeholder="Ingresar número celular"
                    minLength={10}
                    pattern="[0-9]{10}"
                    maxLength={10}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="w-full mt-4 text-white rounded-md font-medium text-sm px-5 py-2.5 text-center bg-liverpoolPink">
                  Continuar
                </button>

              </form>
            )}
          </div>
        </>
      </div>
    </>

  );

};

export default Login;