import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "../provider/AuthProvider";

import { useNavigate } from "react-router-dom";
import PolicyCard from "../components/policy_card";
import { usePoliza } from "../provider/PolizaProvider";

import OdometerIcon from '../assets/icon/Odometer.png';
import CameraIcon from '../assets/icon/Shape.png';

//Funcion para DataLayer Google 
import Modal from "../components/Modal";
import { pushToDataLayer } from "../lib/pushToDataLayer";


const Dashboard = ({ textFooterLegal, textAvisoPrivacidad, setTextFooterLegal, setTextAvisoPrivacidad }) => {
  const { user, logout } = useAuth();
  const { launchPoliza, removePoliza } = usePoliza();
  const [polizas, setPolizas] = useState([]);
  const navigate = useNavigate();
  const loadingElement = document.getElementById('loading');

  useEffect(() => {
    // Ejecuta las llamadas solo si los datos legales no están definidos
    if (!textFooterLegal || !textAvisoPrivacidad) {
      const fetchLegalData = async () => {
        loadingElement.classList.remove('hidden');
        document.body.style.overflow = 'hidden';
        try {
          // Cargar Footer Legal
          if (!textFooterLegal) {
            const footerLegal = await axios.get(import.meta.env.VITE_BACK_URL + "/api/footer_legal");
            if (footerLegal.status === 200) {
              setTextFooterLegal(footerLegal.data?.legal);
            }
          }

          // Cargar Aviso de Privacidad
          if (!textAvisoPrivacidad || textAvisoPrivacidad?.length === 0) {
            const avisoPrivacidad = await axios.get(import.meta.env.VITE_BACK_URL + "/api/legals");
            if (avisoPrivacidad.status === 200) {
              setTextAvisoPrivacidad(avisoPrivacidad.data?.aviso_privacidad);
            }
          }
        } catch (error) {
          console.error("Error al obtener datos legales:", error);
        } finally {
          loadingElement.classList.add('hidden');

        }
      };

      fetchLegalData();
    }
  }, []);


  useEffect(() => {
    const getPolizas = async () => {
      if (polizas.length == 0) {
        loadingElement.classList.remove('hidden');
        document.body.style.overflow = 'hidden';
        removePoliza();
        axios
          .get(import.meta.env.VITE_BACK_URL + "/api/user", {
            headers: {
              Authorization: "Bearer " + user.authToken,
            },
          })
          .then(({ data, status }) => {
            if (status == 200) {
              setPolizas(data.data.policies);
              document.body.style.overflow = '';
              const eventParams = {
                event_category: 'Polizas Contratadas',
                step: '3 Informacion Polizas Contratadas',
              }
              pushToDataLayer('Polizas_contratadas', eventParams)
            }
          })
          .catch((error) => {
            if (error.response.status == 401) {
              logout();
            }
            loadingElement.classList.add('hidden');
            document.body.style.overflow = '';
            console.error(error.response.status);
          })
      }
    }

    getPolizas();
  }, []);


  useEffect(() => {
    if (polizas.length != 0) {
      loadingElement.classList.add('hidden');
    }
  }, [polizas])

  useEffect(() => {
    const eventParams = {
      escenario: 'Home',
      phone: JSON.parse(localStorage.getItem('user')).celphone,
      pageview: 'Polizas contratadas',
      products_available: polizas?.filter(poliza => poliza.policy.reportState !== 12).length,
      products_activated: polizas?.filter(poliza => poliza.policy.reportState === 12).length
    }
    pushToDataLayer('autopagoxkm_seleccion_producto_screenview', eventParams)
  }, [])

  const handleFotosIniciales = (poliza) => {
    removePoliza();
    launchPoliza(poliza);
    const eventParamsFotosIniciales = {
      event_category: 'Tomar fotografias',
      step: '3A Tomar fotografias',
      click: 'Tomar fotografias',
      poliza: poliza.policy.id,
    }
    pushToDataLayer('clic_tomar_fotografia', eventParamsFotosIniciales)

    const eventParamsPromass = {
      escenario: 'Home',
      phone: JSON.parse(localStorage.getItem('user')).celphone,
      pageview: 'Polizas contratadas',
      poliza: poliza.policy.id,
      link_name: 'Tomar fotografias',
    }
    pushToDataLayer('autopagoxkm_seleccion_producto_screenview', eventParamsPromass)
    navigate('/fotos_iniciales');
  }

  const handleReporteOdometro = (poliza) => {
    removePoliza();
    launchPoliza(poliza);
    const eventParams = {
      event_category: 'Reporte mensual odometro',
      step: '0 Tomar fotografia',
      click: 'Tomar fotografia',
      poliza: poliza.policy.id,
    }
    pushToDataLayer('clic_tomar_fotografia', eventParams)
    navigate('/reporte_mensual');
  }
  const handlePagoMensual = (poliza) => {
    removePoliza();
    launchPoliza(poliza);
    navigate('/pago_mensual');
  }
  const handleAuditoria = (poliza) => {
    removePoliza();
    launchPoliza(poliza);
    navigate('/auditoria');
  }

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="w-full ">
      <div className="card !rounded-none">
        <p className="font-bold text-xl text-liverpoolCarbon text-center"> ¡Hola {user.name}!</p>
      </div>
      <div className="container px-4 mx-auto">

        <div
          className=" mx-auto m-5 text-gray-700 bg-white shadow-md bg-clip-border w-full"
        >
          <div className="p-4 border-b">
            <p className="font-bold">Información importante</p>

          </div>
          <div className="p-4">
            <p className="text-sm text-liverpoolLead">
              Por favor, sigue las siguientes instrucciones para registrar las fotografías de tu auto en un plazo no mayor a 24 horas.
            </p>
            <div className="flex items-start gap-2 my-4">
              <div className="h-auto w-auto"><img src={CameraIcon} alt="Camera Icon" width={40} height={34} /></div>
              <div>
                <div className="col-span-2 ..."><h3 className="text-md font-bold">Envía las fotos de tu auto</h3></div>
                <div className="row-span-2 col-span-2 ..."><p className="text-sm font-light">Manda cuatro fotos de tu auto, servirán como evidencia de su estado actual.</p></div>

              </div>
            </div>
            <div className="flex items-start gap-2 my-4">
              <div className="row-span-3 h-auto w-auto"><img src={OdometerIcon} alt="Camera Icon" width={44} height={34} /></div>
              <div>
                <div className="col-span-2 ..."><h3 className="text-md font-bold">Envía la foto de tu odómetro</h3></div>
                <div className="row-span-2 col-span-2 ..."><p className="text-sm font-light">Toma una foto de tu odómetro para saber los kilómetros que actualmente tiene tu vehículo.</p></div>
              </div>
            </div>
          </div>
        </div>
        <div
          className=" text-liverpoolCarbon"
        >
          <p>Estas son tus pólizas contratadas:</p>
        </div>

        {polizas.length > 0 &&
          polizas.map((poliza, index) => {
            return (
              <PolicyCard key={index} poliza={poliza} onHandleFotosIniciales={() => handleFotosIniciales(poliza)} onHandleReporteOdometro={() => (handleReporteOdometro(poliza))} onHandlePagoMensual={() => handlePagoMensual(poliza)} onHandleAuditoria={() => handleAuditoria(poliza)} />
            );
          })}
      </div>
      <div className="text-center pb-4 mt-16">
        <button className="link text-xs" onClick={(event) => {
          event.preventDefault()
          openModal()
        }}>
          Consulta Términos legales
        </button>
      </div>
      {isModalOpen && (
        <Modal className="text-center" description={textFooterLegal} closeModal={closeModal} />
      )}
    </div>
  );
};

export default Dashboard;
