import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import arrow_rigth from "../../assets/icon/down.svg";
import logo_miituo from "../../assets/icon/miituo_pago.svg";
import Alerta from "../../components/Alerta";
import { useAuth } from "../../provider/AuthProvider";
import { usePoliza } from "../../provider/PolizaProvider";
import { useResumePago } from "../../provider/ResumePagoProvider";

// Datalayer Google Tag Manager
import Modal from "../../components/Modal";
import { pushToDataLayer } from "../../lib/pushToDataLayer";

const PagoMensual = ({ textFooterLegal }) => {
  const { user } = useAuth();
  const { poliza } = usePoliza();
  const { launchResumePago } = useResumePago();
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [polizaInfo, setPolizaInfo] = useState(null);
  const [pagoModal, setPagoModal] = useState(false);
  const [card, setCard] = useState(null)
  const [typeCard, setTypeCard] = useState(null);
  const [fechaExpira, setFechaExpira] = useState(null);
  const [fechaExpiraMes, setFechaExpiraMes] = useState(null);
  const [fechaExpiraAnio, setFechaExpiraAnio] = useState(null);
  const [nombreTarjeta, setNombreTarjeta] = useState(null);
  const [cvv, setCvv] = useState(null);

  const [listCards, setListCards] = useState([]);

  //banderas para mostrar formulario de pagar y resumen
  const [paso, setPaso] = useState(0);

  // state para componenete Alerta
  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  // progress bar
  // const [stepProgressBar, setStepProgressBar] = useState(1)

  function formatNumber(num) {
    return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const navigate = useNavigate();

  useEffect(() => {
    if (poliza) {
      const loadingElement = document.getElementById('loading');
      loadingElement.classList.remove('hidden');
      getInfoPoliza();
      getPaymentInfo();
      getListCard();

    }
    else {
      navigate('/dashboard')
    }
  }, [poliza]);

  const getPaymentInfo = async () => {
    const loadingElement = document.getElementById('loading');
    const info_month_res = await axios.get(
      import.meta.env.VITE_BACK_URL + "/api/payment/info_month/" + poliza.policy.id,
      {
        headers: {
          Authorization: "Bearer " + user.authToken,
        },
      }
    );
    if (info_month_res.data.status == "success") {
      setPaymentDetails(info_month_res.data.result);
    }
    loadingElement.classList.add('hidden');
    return info_month_res;
  };

  const getListCard = async () => {
    const listCards_res = await axios.get(
      import.meta.env.VITE_BACK_URL + "/api/list/cards",
      {
        headers: {
          Authorization: "Bearer " + user.authToken,
        },
      }
    );
    if (listCards_res.data.status == "success") {
      setListCards(listCards_res.data.result);
    }
    return listCards_res;
  };

  const getInfoPoliza = async () => {

    const info_poliza_res = await axios.get(
      import.meta.env.VITE_BACK_URL + "/api/details/policy/info/" + poliza.policy.id,
      {
        headers: {
          Authorization: "Bearer " + user.authToken,
        },
      }
    );
    if (info_poliza_res.data.status == "success") {
      setPolizaInfo(info_poliza_res.data.result);
      const info_poliza = info_poliza_res.data.result;
      if (info_poliza.PlocyModel.Id_statusReporte !== 24) {
        navigate("/dashboard");
      }

    }
    else {
      setIsAlert(true)
      setAlertMessage(info_poliza_res.message || info_poliza_res)
    }
    return info_poliza_res;
  };

  const handleChange = (e) => {
    // console.log(typeCard?.name)
    switch (e.target.name) {
      case 'card':
        setCard(e.target.value)
        break;
      case 'type_card':
        let tipo = JSON.parse(e.target.value);
        setTypeCard(tipo);
        break;
      case 'fecha_expira':
        setFechaExpira(e.target.value);
        break;
      case 'nombre_tarjeta':
        setNombreTarjeta(e.target.value);
        break;
      case 'cvv':
        setCvv(e.target.value);
        break;
      default:
        break;
    }
  }

  const handleConfirm = async () => {
    const loadingElement = document.getElementById('loading');
    loadingElement.classList.remove('hidden');
    try {
      const res_payment = await axios.post(import.meta.env.VITE_BACK_URL + '/api/payment/' + poliza.policy.id, {
        card: card,
        cvv: cvv,
        fecha_expira: fechaExpiraAnio + fechaExpiraMes,
        mes: polizaInfo?.PlocyModel?.Mensualidad,
        nombre_tarjetahabiente: nombreTarjeta,
        tarjeta: {
          name: typeCard.name,
          type: typeCard.type
        }
      }, {
        headers: {
          Authorization: "Bearer " + user.authToken,
        },
      });
      if (res_payment.data?.status == 'success') {
        launchResumePago(res_payment.data?.pedido);
        const eventParams = {
          event_category: 'Reporte mensual odometro',
          step: '5.2D Pagar',
          insurance_plan: poliza.policy.coverage,
          quote_number: poliza.policy.id,
          click: 'Pagar'
        }
        pushToDataLayer('clic_agregar_tarjeta_pagar', eventParams)

        navigate('/agradecimiento_pago_mensual');
      }
      loadingElement.classList.add('hidden');
    } catch (error) {
      loadingElement.classList.add('hidden');

      setIsAlert(true)
      setAlertMessage(error.response.data.message || error.message)
    }
  }

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="space-y-6 container px-4 mt-5">
      <div>
        <Alerta isAlertaOpen={isAlert} setAlert={setIsAlert} isTextAlert={alertMessage} isIconClose={'error'} isTimeClose={4000} />
      </div>
      <div className="card">
        <div className='flex justify-between w-full gap-2'>

          <div className=''>
            <img
              src={logo_miituo}
              alt="Logotipo de miituo"
              className="h-full w-auto"
            />
          </div>
          <div className='space-y-2 text-liverpoolCarbon'>
            <p className='text-xs md:text-base mb-[-10px]'>
              Paquete: {paymentDetails?.cobertura}
            </p>
            <p className='text-xs md:text-base '>
              Núm Cotización: {paymentDetails?.cotizacion_id}
            </p>
          </div>
          <div className='grid content-center'>
            <p className='text-xs md:text-base font-bold text-red-500'>
              ${formatNumber(paymentDetails?.tarifa)}{' '}por km
            </p>
          </div>
        </div>
      </div>
      {
        paso === 0 && (
          <div className="space-y-8">
            <div className="card">
              <div className="card-header">
                <p className="text-sm font-normal">Información de Tarjeta</p>
              </div>
              <div>
                <div
                  className="flex items-center justify-between cursor-pointer !mt-4"
                  onClick={() => {

                    const eventParams = {
                      event_category: 'Reporte mensual odometro',
                      step: '5.2 Agregar tarjeta',
                      click: 'Agregar tarjeta'
                    }
                    pushToDataLayer('clic_agregar_tarjeta', eventParams)
                    setPaso(1)
                  }}
                >
                  <p className="text-sm">Agregar tarjeta de crédito/débito</p>
                  <img
                    height={30}
                    width={30}
                    className=""
                    src={arrow_rigth}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div>
              <p className="text-xs md:text-base text-liverpoolCarbon text-center">
                Al hacer clic aceptas los {' '}
                <a className="text-liverpoolPink" href={import.meta.env.VITE_BACK_URL + "/api/pdf_terminos_condiciones"} target="_blank">
                  Términos y condiciones
                </a>,{' '}
                <a className="text-liverpoolPink" href="/aviso_privacidad" target="_blank">
                  Aviso de Privacidad,
                </a>{' '}
                Condiciones generales
                de la póliza del seguro,
                así como los puntos antes mencionados.
              </p>
            </div>
          </div>
        )
      }

      {
        paso === 1 && (
          <div className="">
            <div className="card space-y-2">
              <div className="flex justify-between ">
                <p className="text-xs md:text-base text-liverpoolCarbon font-bold">Datos de la tarjeta</p>
                <p className="text-xs md:text-base text-liverpoolRed">*Campos obligatorios</p>
              </div>
              <div className="relative">
                <input
                  type="text"
                  id="nombreTarjeta"
                  name="nombreTarjeta"
                  value={nombreTarjeta || ""}
                  onChange={(e) => setNombreTarjeta(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-liverpoolLead  block mx-auto w-full py-2 p-2 "
                  placeholder="Nombre (como aparece en la tarjeta)"
                  required
                /><span className="text-red-500 absolute top-0 right-2 mt-2">*</span>
              </div>
              <div className="relative">
                <select className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-liverpoolLead focus:border-liverpoolLead block mx-auto w-full py-2 p-2'
                  id="type_card" name="type_card"
                  onChange={handleChange}
                >
                  <option value={JSON.stringify({})}>Selecciona una opción</option>
                  {
                    listCards.length > 0 && listCards.map((element, index) => {
                      return (<option key={index} value={JSON.stringify(element)} data={element.type}>{element.name}</option>);
                    })
                  }
                </select>
                <span className="text-red-500 absolute top-0 right-6 mt-2">*</span>
              </div>
              <div className="relative">
                <input
                  type="text"
                  id="card"
                  name="card"
                  value={card || ""}
                  onChange={(e) => setCard(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-liverpoolLead  block mx-auto w-full py-2 p-2 "
                  placeholder="Número de tarjeta"
                  maxLength={ typeCard?.name === 'American Express' ? 15 : 16}
                  required
                /><span className="text-red-500 absolute top-0 right-2 mt-2">*</span>
                  <span className={`text-red-500 text-xs
                    ${typeCard?.name === 'American Express' && card?.length < 15 ||
                      typeCard?.name === 'American Express' && card?.length > 15 ||
                      typeCard?.name !== 'American Express' && card?.length < 16 ? 'block ' : 'hidden'} 
                  `}>
                    {typeCard?.name === 'American Express' ? ' Ingresa 15 digitos' : ' Ingresa 16 digitos'}
                  </span>
              </div>
              <div className="flex gap-4 ">

                {/* <div className="relative w-full"> */}
                <div className={`relative w-full ${typeCard?.name === 'Liverpool' ? 'hidden ' : 'block'} `}>
                  <input
                    type="text"
                    id="fecha_expira_mes"
                    name="fecha_expira_mes"
                    value={fechaExpiraMes || ""}
                    onChange={(e) => setFechaExpiraMes(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-liverpoolLead  block mx-auto w-full py-2 p-2 "
                    placeholder="Mes (MM)"
                    maxLength={2}
                    required
                  /><span className="text-red-500 absolute top-0 right-2 mt-2">*</span>
                </div>
                {/* <div className="relative w-full"> */}
                <div className={`relative w-full ${typeCard?.name === 'Liverpool' ? 'hidden ' : 'block'} `}>
                  <input
                    type="text"
                    id="fecha_expira_anio"
                    name="fecha_expira_anio"
                    value={fechaExpiraAnio || ""}
                    onChange={(e) => setFechaExpiraAnio(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-liverpoolLead  block mx-auto w-full py-2 p-2 "
                    placeholder="Año (YY)"
                    required
                    maxLength={2}
                  /><span className="text-red-500 absolute top-0 right-2 mt-2">*</span>
                </div>
              </div>
              <div className="relative">
                <input
                  type="password"
                  id="cvv"
                  name="cvv"
                  value={cvv || ""}
                  onChange={(e) => setCvv(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-liverpoolLead  block mx-auto w-full py-2 p-2 "
                  placeholder="CVV"
                  required
                  maxLength={ typeCard?.name === 'American Express' ? 4 : 3}
                /><span className="text-red-500 absolute top-0 right-2 mt-2">*</span>
                  <span className={`text-red-500 text-xs
                    ${typeCard?.name === 'American Express' && cvv?.length < 4 ||
                      typeCard?.name !== 'American Express' && cvv?.length < 3 || 
                      typeCard?.name !== 'American Express' && cvv?.length > 3 ? 'block ' : 'hidden'} 
                  `}>
                    {typeCard?.name === 'American Express' ? ' Ingresa 4 digitos' : ' Ingresa 3 digitos'}
                  </span>
              </div>
              <div>
                <button
                  className="background-color-liverpool w-full items-center px-4 py-2 border border-transparent rounded-md text-white hover:bg-liverpoolHover focus:bg-liverpoolHover active:bg-liverpoolHover focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition ease-in-out duration-150 disabled:opacity-25"
                  type="button"
                  disabled={ 
                    !nombreTarjeta || 
                    !typeCard || 
                    typeCard?.name !== 'Liverpool' && !fechaExpiraMes || 
                    typeCard?.name !== 'Liverpool' && !fechaExpiraAnio || 
                    !card || 
                    !cvv ||
                    // validacion no de tarjeta
                    typeCard?.name === 'American Express' && card?.length < 15 ||
                    typeCard?.name === 'American Express' && card?.length > 15 ||
                    typeCard?.name !== 'American Express' && card?.length < 16 ||
                    // validacion cvv
                    typeCard?.name === 'American Express' && cvv?.length < 4 ||
                    typeCard?.name !== 'American Express' && cvv?.length < 3 || 
                    typeCard?.name !== 'American Express' && cvv?.length > 3
                  }
                  onClick={() => {
                    handleConfirm()
                  }}
                >
                  Pagar
                </button>
              </div>
            </div>
          </div>
        )
      }

      <div className="text-center pb-4 mt-16">
        <button className="link text-xs" onClick={(event) => {
          event.preventDefault()
          openModal()
        }}>
          Consulta Términos legales
        </button>
      </div>
      {isModalOpen && (
        <Modal className="text-center" description={textFooterLegal} closeModal={closeModal} />
      )}
    </div>
  );
};

export default PagoMensual;
