import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import checkOnlineStatus from "./hooks/checkOnlineStatus.js";
import detectDeviceType from "./hooks/detectDevideType";
import "./index.css";
import AuthProvider from "./provider/AuthProvider.jsx";
import PolizaProvider from "./provider/PolizaProvider.jsx";
import ResumePagoProvider from "./provider/ResumePagoProvider.jsx";

const Main = () => {
  const [device, setDevice] = useState("");
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const deviceType = detectDeviceType();
    setDevice(deviceType);
    // Verificar si existe aun esta conectado a la red
    const unsubscribe = checkOnlineStatus(setIsOnline);
    // Limpieza al desmontar el componente
    return () => unsubscribe();
  }, []);

  return (
    <React.StrictMode>
      <AuthProvider>
        <PolizaProvider>
          <ResumePagoProvider>
            {/* <Navbar /> */}
            {isOnline ? (
              device === "mobile" ? (
                <>
                  <App />
                  {/* <div className="text-center pb-4">
                    <button className="link text-xs" onClick={(event) => {
                      event.preventDefault()
                      openModal()
                    }}>
                      Consulta Términos legales
                    </button>
                  </div> */}

                  {/* Modal */}

                </>
              ) : (
                <div className="card m-auto mx-4">
                  <p className="text-center text-2xl">
                    Lo sentimos, pero para continuar con la aplicación es necesario
                    que accedas desde un dispositivo móvil. Agradecemos tu
                    comprensión.
                  </p>
                </div>
              )
            ) : (
              <div className="card m-auto mx-4">
                <p className="text-center text-2xl">
                  Lo sentimos, pero parece que no hay conexión a la red. Por favor,
                  verifica tu conexión a internet y vuelve a intentarlo.
                </p>
              </div>
            )}
          </ResumePagoProvider>
        </PolizaProvider>
      </AuthProvider>
    </React.StrictMode>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(<Main />);
