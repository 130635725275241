
import { memo, useEffect, useMemo, useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import Navbar from './components/navbar';
import AvisoPrivacidad from './pages/AvisoPrivacidad';
import Dashboard from './pages/dashboard';
import Login from './pages/login';
import Auditoria from './pages/poliza/auditoria';
import FotosIniciales from './pages/poliza/fotos_iniciales';
import InfoPagoMensual from './pages/poliza/info_pago_mensual';
import InfoActivaPoliza from './pages/poliza/info_poliza_activa';
import ThkPayMonth from './pages/poliza/pagina_agradecimiento';
import PagoMensual from './pages/poliza/pago_mensual';
import ReporteOdometro from './pages/poliza/reporte_odometro';
import { useAuth } from './provider/AuthProvider';
import { usePoliza } from './provider/PolizaProvider';

/* eslint-disable react/display-name */
const PrivateRoute = memo(({ children, user }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login'); // Redirige a /login si no hay usuario autenticado
    }
  }, [user, navigate]);

  // No renderiza nada si no hay usuario autenticado
  if (!user) {
    return null;
  }

  return children;
});

function App() {
  const { user } = useAuth();
  const { poliza } = usePoliza();

  const PolizaRoute = ({ children }) => {
    if (!poliza) {
      return <Navigate to={'/dashboard'} />
    }
    return children;
  }
  const [textFooterLegal, setTextFooterLegal] = useState('');
  const [textAvisoPrivacidad, setTextAvisoPrivacidad] = useState([]);
  const memoizedTextFooterLegal = useMemo(() => textFooterLegal, [textFooterLegal]);
  const memoizedTextAvisoPrivacidad = useMemo(() => textAvisoPrivacidad, [textAvisoPrivacidad])

  // const closeModal = () => setIsModalOpen(false);
  return (
    <Router>
      <Routes>
        <Route index path="/" element={<Login />} />
        <Route path="/dashboard" element={
          <PrivateRoute user={user}>
            <Navbar title="Seguro de Auto Pago por Kilómetro" />
            <Dashboard textFooterLegal={memoizedTextFooterLegal} textAvisoPrivacidad={memoizedTextAvisoPrivacidad} setTextFooterLegal={setTextFooterLegal} setTextAvisoPrivacidad={setTextAvisoPrivacidad} />
          </PrivateRoute>
        } />
        <Route path='/fotos_iniciales' element={
          <PolizaRoute>
            <Navbar title="Seguro de Auto Pago por Kilómetro" />
            <FotosIniciales textFooterLegal={textFooterLegal} />
          </PolizaRoute>
        } />
        <Route path='/reporte_mensual' element={
          <PolizaRoute>
            <Navbar title="Tu odómetro" />
            <ReporteOdometro textFooterLegal={memoizedTextFooterLegal} />
          </PolizaRoute>
        } />
        <Route path='/pago_mensual' element={
          <PolizaRoute>
            <Navbar title="Recibo del mes" />
            <PagoMensual textFooterLegal={memoizedTextFooterLegal} />
          </PolizaRoute>
        } />
        <Route path='/auditoria' element={
          <PolizaRoute>
            <Navbar title="Carga de fotografías" />
            <Auditoria textFooterLegal={memoizedTextFooterLegal} />
          </PolizaRoute>
        } />
        <Route path='/info_pago_mensual' element={
          <PolizaRoute>
            <Navbar title="Información de tu pago" />
            <InfoPagoMensual textFooterLegal={memoizedTextFooterLegal} />
          </PolizaRoute>
        } />
        <Route path='/agradecimiento_pago_mensual' element={
          <PolizaRoute>
            {/* <Navbar title="Liverpool pocket" /> */}
            <ThkPayMonth textFooterLegal={memoizedTextFooterLegal} />
          </PolizaRoute>
        } />
        <Route path='/informacion_poliza_activa' element={
          <PolizaRoute>
            <Navbar title="Mi póliza" />
            <InfoActivaPoliza textFooterLegal={memoizedTextFooterLegal} />
          </PolizaRoute>
        } />
        <Route path='/aviso_privacidad' element={
          <>
            <Navbar returnUrl={false} title="Aviso de Privacidad" />
            <AvisoPrivacidad textAvisoPrivacidad={memoizedTextAvisoPrivacidad} />
          </>
        } />
      </Routes>
    </Router>
  )
}

export default App
